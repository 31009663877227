<template>
    <div>
        <div class="demo-spin-article">
            <div class="col-md-12 mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('fixedIncrntiveBonus.employee')
                }}</label>
                <Select
                    v-model="model.employee_id"
                    filterable
                    :remote-method="loadEmployee"
                    :clearable="true"
                    :class="{
                        'is-invalid': errors.has('employee_id')
                    }"
                >
                    <Option
                        v-for="(employeeProfile, index) in employeeProfiles"
                        :value="employeeProfile.employee_id"
                        :key="index"
                        :label="employeeProfile.employee_name_en"
                        >{{ employeeProfile.employee_name_en }}|{{
                            employeeProfile.employee_name_kh
                        }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('employee_id')">
                    {{ errors.first('employee_id') }}
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('fixedIncrntiveBonus.incentiveBonusName')
                    }}</label>
                    <Select
                        v-model="model.inc_bonus_id"
                        :class="{
                            'is-invalid': errors.has('inc_bonus_id')
                        }"
                    >
                        <Option
                            v-for="(payItemIncentiveBonus,
                            index) in payItemIncentiveBonuses"
                            :value="payItemIncentiveBonus.inc_bonus_id"
                            :key="index"
                            :label="payItemIncentiveBonus.inc_bonus_item"
                            >{{ payItemIncentiveBonus.inc_bonus_item }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('inc_bonus_id')"
                    >
                        {{ errors.first('inc_bonus_id') }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('fixedIncrntiveBonus.incentiveBonusAmount')
                    }}</label>
                    <div class="tw-whitespace-nowrap">
                        <Poptip trigger="focus">
                            <Input
                                v-model.number="model.inc_bonus"
                                type="number"
                                :placeholder="
                                    $t('fixedIncrntiveBonus.enterNumber')
                                "
                                style="width: 179%"
                                ref="inc_bonus"
                                :class="{
                                    'is-invalid': errors.has('inc_bonus')
                                }"
                            >
                            </Input>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('inc_bonus')"
                            >
                                {{ errors.first('inc_bonus') }}
                            </div>
                            <div slot="content">
                                {{
                                    model.inc_bonus
                                        ? formatNumber(model.inc_bonus)
                                        : $t('fixedIncrntiveBonus.enterNumber')
                                }}
                            </div>
                        </Poptip>
                    </div>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('inc_bonus')"
                    >
                        {{ errors.first('inc_bonus') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('fixedIncrntiveBonus.effectiveDate')
                    }}</label>
                    <div>
                        <date-Picker
                            v-model="model.effective_date"
                            type="date"
                            placeholder="Select date"
                            style="width:320px"
                            @on-change="cycleDateChange"
                            :class="{
                                'is-invalid': errors.has('effective_date')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('effective_date')"
                        >
                            {{ errors.first('effective_date') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label tw-text-xs">{{
                        $t('fixedIncrntiveBonus.expiryDate')
                    }}</label>
                    <div>
                        <date-Picker
                            v-model="model.expiry_date"
                            type="date"
                            placeholder="Select date"
                            style="width:320px"
                            @on-change="expiryDateChange"
                        ></date-Picker>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('fixedIncrntiveBonus.description')
                }}</label>
                <div>
                    <Input
                        v-model="model.description"
                        type="textarea"
                        :rows="5"
                        placeholder="Enter something..."
                    />
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        class="btn-gray"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { isEmpty } from 'lodash'

export default {
    name: 'fixed-incentive-bonus-create',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                employee_id: null,
                inc_bonus_id: null,
                inc_bonus: null,
                effective_date: null,
                expiry_date: null,
                description: null
            }
        }
    },
    computed: {
        ...mapGetters(['formatNumber']),
        ...mapState('payroll/fixedIncentiveBonus', ['edit_data', 'inc_bonus']),

        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        employeeProfiles () {
            return this.$store.state.payroll.fixedIncentiveBonus
                .employeeProfiles
        },
        payItemIncentiveBonuses () {
            return this.$store.state.payroll.fixedIncentiveBonus
                .payItemIncentiveBonuses
        }
    },
    methods: {
        ...mapActions('payroll/fixedIncentiveBonus', [
            'getEmployeeProfile',
            'grtPayItemAcentiveBonus'
        ]),
        fetchResource () {
            this.loading = true
            this.grtPayItemAcentiveBonus()
            this.getEmployeeProfile()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),

        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/fixedIncentiveBonus/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/fixedIncentiveBonus/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        cycleDateChange (value) {
            this.model.effective_date = value
        },
        expiryDateChange (value) {
            this.model.expiry_date = value
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployeeProfile({
                    search: this.edit_data.employee_profile.employee_name_en
                })
                this.model.employee_id = this.edit_data.employee_id
                this.model.inc_bonus_id = this.edit_data.inc_bonus_id
                this.model.inc_bonus = this.edit_data.inc_bonus
                this.model.effective_date = this.edit_data.effective_date
                this.model.expiry_date = this.edit_data.expiry_date
                this.model.description = this.edit_data.description
            }
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/fixedIncentiveBonus/update', {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.inc_bonus_id = null
            this.model.inc_bonus = null
            this.model.effective_date = null
            this.model.expiry_date = null
            this.model.description = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'FIXED INCENTIVE BONUS',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
